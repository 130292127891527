import React from 'react';
import SubNavTagline from '../components/SubNavTagline';
import MontereyAL from '../assets/images/Monterey AL Cropped.png';
import MontereyALApplication from '../assets/images/MontereyAL-Application.png';
import Freehand1 from '../assets/images/Freehand 1.png';
import Freehand2 from '../assets/images/Freehand 2.png';
import Partiallyguided1 from '../assets/images/Partially guided 1.png';
import Partiallyguided2 from '../assets/images/Partially guided 2.png';
import Guided1 from '../assets/images/Guided 1.png';
import Guided2 from '../assets/images/Guided 2.png';
import MontereyALanatomy from '../assets/images/Monterey AL anatomy1.png';
import ResistancetoSubsidenceGraph from '../assets/images/Resistance to Subsidence Graph.png';
import ExpulsionForceGraph from '../assets/images/Expulsion Force Graph.png';

import xray from '../assets/images/22.jpg';

class Product1 extends React.Component {
	render() {
		return (
			<div className="ProductPage">
				<SubNavTagline
					tagline={
						<span className="egyptienne">
							Information for <strong>healthcare professionals</strong>
						</span>
					}
				/>
				<hr />
				<div className="mainSection" style={{ position: 'relative' }}>
					<div className="contentContainer">
						<div className="flex-ac mt-75">
							<div style={{ maxWidth: 642, marginRight: 83 }}>
								<div className="egyptienne mainTitle">
									Fixated on <strong>science</strong>
								</div>
								<h3 className="mt-15 egyptienne">
									<strong className="gold">Monterey AL Interbody System</strong>
									<br />
									Anterior Lumbar Cage
								</h3>
								<p style={{ marginTop: 30 }}>
									The Monterey AL Cage is designed for indirect decompression with an array of
									footprints, heights and lordotic options built using our AMagine manufacturing
									process and incorporating our proprietary Tritanium In-Growth Technology.<sup>1</sup>
								</p>
							</div>
							<img style={{ width: 281 }} alt="" src={MontereyAL} />
						</div>
						<hr className="mt-75" />
						<div className="flex mt-75">
							<div style={{ maxWidth: 491, marginRight: 70 }}>
								<h2 className="egyptienne">
									Biomechanical <strong className="gold">testing</strong>
								</h2>
								<p className="humst-black mt-25">Resistance to subsidence</p>

								<div className="bulletPoint mt-20">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Monterey AL showed greater resistance to subsidence than a group of FDA cleared
										ALIF, LLIF, TLIF cages.<sup>2,3</sup>
									</p>
								</div>
							</div>
							<div style={{ maxWidth: 495 }}>
								<img
									className="mt-75"
									alt=""
									src={ResistancetoSubsidenceGraph}
									style={{ width: 495, display: 'unset' }}
								/>
								<div style={{ fontSize: 10, color: '#b1b4b3' }}>
									*This study was limited to a sampling of cages, cleared by FDA between 2007 through
									2016, without integrated fixation, expandable features, or coatings.
								</div>
								<div style={{ fontSize: 10, color: '#b1b4b3' }}>
									**Assumes the use of all three points of integrated fixation{' '}
								</div>
							</div>
						</div>
						<hr className="mt-40" />

						<div className="flex mt-40">
							<div style={{ maxWidth: 491, marginRight: 70 }}>
								<p className="humst-black">Expulsion force</p>

								<div className="bulletPoint mt-20">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Performance tests compared to literature show that the Monterey AL cage can
										potentially withstand over 7x the typical lumbar disc space shear expulsive load
										in patients<sup>2,4</sup>
									</p>
								</div>
							</div>
							<div>
								<img
									alt=""
									src={ExpulsionForceGraph}
									style={{ width: 495, height: '100%', display: 'unset' }}
								/>
								<div style={{ fontSize: 10, color: '#b1b4b3', textAlign: 'center' }}>
									**Assumes the use of all three points of integrated fixation
								</div>
							</div>
						</div>
						<div className="flex-ac mt-75 reverseMobile">
							<img
								alt=""
								src={xray}
								style={{ width: 495, height: '100%', marginRight: 70, display: 'unset' }}
							/>
							<div>
								<h2 className="egyptienne">
									Designed for <strong className="gold">visualization</strong>
								</h2>
								<p className="mt-20">
									Created to allow imaging and designed with lateral windows, the Monterey AL cage
									allows visualization on CT and X-ray<sup>5</sup>
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className="gradientSection mt-75">
					<h2 className="egyptienne white" style={{ textAlign: 'center' }}>
						Intuitive <strong className="gold">instruments</strong>
					</h2>
					<div className="contentContainer">
						<div className="flex mt-40 instruments">
							<div className="flex-ac" style={{ flexDirection: 'column' }}>
								<div className="egyptienne white" style={{ fontSize: 29 }}>
									Free-hand
								</div>
								<div className="flex" style={{ marginTop: 22 }}>
									<img
										alt=""
										src={Freehand1}
										style={{ marginRight: 25 }}
										className="instrumentPicture"
									/>
									<img alt="" src={Freehand2} className="instrumentPicture" />
								</div>
							</div>
							<div className="flex-ac" style={{ flexDirection: 'column' }}>
								<div className="egyptienne white" style={{ fontSize: 29 }}>
									Partially Guided
								</div>
								<div className="flex" style={{ marginTop: 22 }}>
									<img
										alt=""
										src={Partiallyguided1}
										style={{ marginRight: 25 }}
										className="instrumentPicture"
									/>
									<img alt="" src={Partiallyguided2} className="instrumentPicture" />
								</div>
							</div>
							<div className="flex-ac" style={{ flexDirection: 'column' }}>
								<div className="egyptienne white" style={{ fontSize: 29 }}>
									Fully Guided
								</div>
								<div className="flex" style={{ marginTop: 22 }}>
									<img
										alt=""
										src={Guided1}
										style={{ marginRight: 25 }}
										className="instrumentPicture"
									/>
									<img alt="" src={Guided2} className="instrumentPicture" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="contentContainer">
					<img className="mt-75" style={{ width: '100%' }} alt="" src={MontereyALanatomy} />
				</div>
				<div className="section mt-75">
					<div className="contentContainer">
						<h2 className="egyptienne">
							Monterey AL Cage <strong className="gold">technical data</strong>
						</h2>
						<hr className="mt-40" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
								Tritanium material
							</p>
							<p style={{ width: '50%' }}>Titanium alloy</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
							Tritanium mean porosity range <sup>6</sup>
							</p>
							<p style={{ width: '50%' }}>55 - 65%</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
							Tritanium mean pore size range <sup>6</sup>
							</p>
							<p style={{ width: '50%' }}>400 - 500μm</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
							Tritanium pore size range <sup>6</sup>
							</p>
							<p style={{ width: '50%' }}>100 - 700μm</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
							Tritanium pore interconnectedness
							</p>
							<p style={{ width: '50%' }}>Full endplate to endplate</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
								Footprints
							</p>
							<p style={{ width: '50%' }}>25x33mm, 28x36mm, 31x36mm, 31x40mm, 34x40mm</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
								Lordosis
							</p>
							<p style={{ width: '50%' }}>10° - 30°, in 5° increments</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
								Heights
							</p>
							<p style={{ width: '50%' }}>8 - 22mm</p>
						</div>
						<hr className="mt-15" />
						<hr className="mt-75" />
						<div style={{ fontSize: 11, lineHeight: '19px', marginTop: 25, marginBottom: 50 }}>
							<span style={{ color: '#B1B4B3' }}>
								1. Stryker data on file; PROJ 43909 Tritanium technology claim support memo <br />
								2. Stryker data on file; TREP0000065981 <br />
								3. Peck et. al “Mechanical performance of lumbar intervertebral body fusion devices: An
								analysis of data submitted to the Food and Drug Administration.” Journal of
								Biomechanics. 2018 Drug Administration.” Journal of Biomechanics. 2018 <br />
								4. Potvin et. al. “Reduction in anterior shear forces on the L4/L5 disc by the lumbar
								musculature” Clinical Biomechanics 1991; 6: 88-96 <br />
								5. Stryker data on file; PROJ 71050 Tritanium AL imaging marketing memo<br />
								6. Stryker data on file; DHF 42351 Tritanium PL
							</span>
							<br />
							<br />
							Refer to the Monterey AL, Tritanium PL, Tritanium TL, and Tritanium C surgical techniques
							and instructions for use for complete product information<br />
							<br />
							This website is intended for physicians only. It is not intended for patients. If you are a
							patient, you should not rely on the information on this website and should speak to your
							doctor about whether spinal fusion surgery is right for you.<br />
							<br />
							A surgeon must always rely on his or her own professional clinical judgment when deciding
							whether to use a particular product when treating a particular patient. Stryker does not
							dispense medical advice and recommends that surgeons be trained in the use of specific
							products before using them in surgery.<br />
							<br />
							The information presented is intended to demonstrate the breadth of Stryker product
							offerings. A surgeon must always refer to the package insert, product label and/or
							instructions for use before using any Stryker product. Products may not be available in all
							markets because product availability is subject to the regulatory and/or medical practices
							in individual markets. Please contact your Stryker representative if you have questions
							about the availability of Stryker products in your area.
							<br />
							<br />
							Stryker Corporation or its divisions or other corporate affiliated entities own, use or have
							applied for the following trademarks or service marks: AMagine, Built to fuse,Stryker,
							Tritanium. All other trademarks are trademarks of their respective owners or holders.<br />
							<br />
							TRITA-WB-2_Rev 3_34241 <br />
							<br />
							Copyright &copy; 2022 Stryker
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Product1;
