import React from 'react';

class SubNavTagline extends React.Component {
	render() {
		return (
			<div className="contentContainer sub-nav-tagline">
				<div>{this.props.tagline}</div>
			</div>
		);
	}
}

export default SubNavTagline;
