import React from 'react';

class ContentAccordion extends React.Component {
	state = {
		expanded: false
	};

	render() {
		const { expanded } = this.state;
		const { title, titleImage, children } = this.props;
		return (
			<div className={`contentAccordion ${expanded ? 'expanded' : ''}`}>
				<div className="accordionHeader" onClick={() => this.setState({ expanded: !expanded })}>
					<div className="titleImage">
						<img className="roundedImage" alt="" src={titleImage} />
					</div>
					<div className="title">{title}</div>
					<div style={{ marginLeft: 'auto' }} className="expandIcon">
						<div className="iconContainer">
							<div className={`goldBar ${!expanded ? 'h-bar' : ''}`} />
						</div>
						<div className="iconContainer">
							<div className="goldBar" />
						</div>
					</div>
				</div>
				<div className={`accordionBody ${expanded ? 'expanded' : ''}`}>{children}</div>
			</div>
		);
	}
}

export default ContentAccordion;
