import { Route, Routes } from 'react-router-dom';
import Product1 from './Product1';
import Product2 from './Product2';
import Product3 from './Product3';
import Product4 from './Product4';

function Products() {
	return (
		<Routes>
			<Route exact path="monterey-al" element={<Product1 />} />
			<Route exact path="tritanium-pl" element={<Product2 />} />
			<Route exact path="tritanium-tl" element={<Product3 />} />
			<Route exact path="tritanium-c" element={<Product4 />} />
		</Routes>
	);
}

export default Products;
