import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import './App-mobile.css';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Products from './pages/Products';

function usePageViews() {
	let location = useLocation();
	React.useEffect(
		() => {
			window.scrollTo(0, 0);
		},
		[ location ]
	);
}

function App() {
	usePageViews();
	return (
		<div className="App">
			<Navbar />
			<div className="pages">
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/products/*" element={<Products />} />
				</Routes>
			</div>
		</div>
	);
}

export default App;
