import React from 'react';
import SubNavTagline from '../components/SubNavTagline';
import TritaniumCHero from '../assets/images/Tritanium C Hero.png';
import LateralXrayImage from '../assets/images/Lateral X-ray Image.png';
import LateralCTImage from '../assets/images/Lateral CT Image.png';
import Shapedforstabilitychart from '../assets/images/Shaped for stability chart.png';
import CageDesignChart1 from '../assets/images/Cage Design Chart 1.png';
import CageDesignChart2 from '../assets/images/Cage Design Chart 2.png';
import SubsidenceChart2 from '../assets/images/Cage Chart.png';

import TechnicalSalesSheet from '../assets/pdf/3434TriC_TechSummaryTRICC-BR-4_15542HINC.pdf';
import CommercialBrochure from '../assets/pdf/ASK_Tritanium_C_Commercial_Brochure_01_22_scroll.pdf';

class Product4 extends React.Component {
	render() {
		return (
			<div className="ProductPage">
				<SubNavTagline
					tagline={
						<span className="egyptienne">
							Information for <strong>healthcare professionals</strong>
						</span>
					}
				/>
				<hr />
				<div className="mainSection mt-75" style={{ position: 'relative', minHeight: 350 }}>
					<div className="contentContainer">
						<div className="flex-ac">
							<div style={{ maxWidth: 350 }}>
								<div className="egyptienne mainTitle">
									Engineered<br />
									<strong>for bone</strong>
								</div>
								<h3 className="mt-15 egyptienne">
									<strong className="gold">
										Tritanium<sup>&reg;</sup> C
									</strong>
									<br />
									Anterior Cervical Cage
								</h3>
							</div>
							<img
								style={{ width: 324, marginLeft: 'auto', marginRight: 50 }}
								alt=""
								src={TritaniumCHero}
							/>
						</div>
						<hr className="mt-75" />
					</div>
				</div>

				<div className="section mt-75">
					<div className="contentContainer">
						<div className="fs-25 futura-bold">
							Inspired by the microstructure of cancellous bone<sup>1</sup> and enabled by AMagine,
							Tritanium C is designed for bone in-growth and biological fixation.
						</div>
						<p>
							The Tritanium C Cage is a hollow implant that consists of a unique configuration of both
							solid and porous structures, which are simultaneously built using our AMagine manufacturing
							process and applying our proprietary Tritanium In-Growth Technology.
						</p>

						<div className="flex mt-75" style={{ justifyContent: 'space-between' }}>
							<div style={{ maxWidth: 495, width: '100%' }}>
								<h2 className="egyptienne">
									Developed to<br />
									<strong className="gold">
										minimize subsidence<sup>2</sup>
									</strong>
								</h2>
								<p className="mt-20">
									The Tritanium C Cage demonstrated better resistance to subsidence than other
									commercially available cervical interbody cages constructed out of different
									materials.<sup>2</sup>
								</p>

								<div className="bulletPoint mt-20">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Large central graft and lateral windows help to reduce the overall stiffness of
										the cage and allow room for bone graft to be packed inside the cage<sup>2</sup>
									</p>
								</div>
								<div className="bulletPoint mt-20">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Tritanium has an elastic modulus that falls between cancellous and cortical bone<sup>3</sup>
									</p>
								</div>
								<div className="bulletPoint mt-20">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Teeth are designed to increase the surface area of the device in contact with
										bone in order to help normalize load transmission and minimize subsidence<sup>3</sup>
									</p>
								</div>
							</div>
							<div>
								<img alt="" src={SubsidenceChart2} style={{ width: 495, marginTop: 59 }} />
								<div style={{ marginTop: 42 }}>
									<div className="flex-ac">
										<i className="las la-file-alt" style={{ fontSize: 29, marginRight: 12.5 }} />
										<div className="fs-12 pointer" style={{ marginRight: 10 }} onClick={()=>window.open(TechnicalSalesSheet, '_blank')}>
											<strong className="gold">TECHNICAL SALES SHEET</strong>
										</div>
										<div className="arrow-right" />
									</div>
									<hr style={{ marginTop: 17 }} />
								</div>
								<div style={{ marginTop: 17 }}>
									<div className="flex-ac">
										<i className="las la-file-alt" style={{ fontSize: 29, marginRight: 12.5 }} />
										<div className="fs-12 pointer" style={{ marginRight: 10 }} onClick={()=>window.open(CommercialBrochure, "_blank")}>
											<strong className="gold">COMMERCIAL BROCHURE</strong>
										</div>
										<div className="arrow-right" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="gradientSection2 mt-75">
					<div className="contentContainer">
						<div className="flex" style={{ justifyContent: 'space-between' }}>
							<div className="flex-ac" style={{ margin: 'auto' }}>
								<img alt="" src={LateralXrayImage} className="gradientImages" />
								<img alt="" className="gradientImages" src={LateralCTImage} />
							</div>
							<div className="white" style={{ maxWidth: 514, width: '100%' }}>
								<h2 className="egyptienne">
									Created to allow <strong className="gold">imaging</strong>
								</h2>
								<p className="mt-25">
									Designed with lateral windows, the Tritanium C Cage allows visualization on CT and
									X-ray.<sup>4</sup>
								</p>
							</div>
						</div>
					</div>
				</div>

				<div className="section">
					<div className="contentContainer">
						<div className="flex mt-75" style={{ justifyContent: 'space-between' }}>
							<div style={{ width: 495 }}>
								<h2 className="egyptienne">
									Shaped for{' '}
									<strong className="gold">
										stability<sup>5,6</sup>
									</strong>
								</h2>
								<p className="mt-25">
									The precisely angled teeth of the Tritanium C Cage are designed to allow
									bidirectional stability, with an expulsion force that was shown to be 9% greater
									than the insertion force.<sup>5</sup>
								</p>

								<div className="bulletPoint mt-20">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Tritanium C cervical cages have a high coefficiency of friction for initial
										stability<sup>6</sup>
									</p>
								</div>
								<div className="bulletPoint mt-20">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Individually sterile-packed, and available in broad range of footprints, heights
										and lordotic angles, the Tritanium C Cage is designed to address varying patient
										anatomy
									</p>
								</div>
								<div className="bulletPoint mt-20 ml-15" style={{marginLeft:25}}>
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Slopes on both the superior and inferior aspects of the cage allow
										hyper-lordotic options designed to optimize sagittal balance
									</p>
								</div>
								<div className="bulletPoint mt-20 ml-15" style={{marginLeft:25}}>
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Indicated for use in one level or two contiguous level cervical interbody
										fusions
									</p>
								</div>
							</div>
							<img
								className="mt-75"
								alt=""
								src={Shapedforstabilitychart}
								style={{ width: 493, height: '100%' }}
							/>
						</div>
						<h2 className="egyptienne mt-75">
							Tritanium C Anterior<br />
							<strong className="gold">Cervical Cage design</strong>
						</h2>
						<img alt="" src={CageDesignChart1} style={{ width: '100%', marginTop: 50 }} />
						<img alt="" src={CageDesignChart2} style={{ width: '100%', marginTop: 50 }} />
					</div>
				</div>

				<div className="section mt-75">
					<div className="contentContainer">
						<h2 className="egyptienne">
							Tritanium C Cage{' '}
							<strong className="gold">
								technical data
							</strong>
						</h2>
						<hr className="mt-40" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
								Tritanium material
							</p>
							<p style={{ width: '50%' }}>Titanium alloy</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
							Tritanium mean porosity range<sup>7</sup>
							</p>
							<p style={{ width: '50%' }}>55 - 65%</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
							Tritanium mean pore size range<sup>7</sup>
							</p>
							<p style={{ width: '50%' }}>400 - 500μm</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
							Tritanium pore size range<sup>7</sup>
							</p>
							<p style={{ width: '50%' }}>100 - 700μm</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
							Tritanium pore interconnectedness
							</p>
							<p style={{ width: '50%' }}>Full endplate to endplate</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
								Footprints
							</p>
							<p style={{ width: '50%' }}>12x14m, 14x17mm</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
								Lordosis
							</p>
							<p style={{ width: '50%' }}>0, 6, and 10&deg;</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
								Heights
							</p>
							<p style={{ width: '50%' }}>5-9 mm</p>
						</div>
						<hr className="mt-15" />
						<hr className="mt-75" />
						<div style={{ fontSize: 11, lineHeight: '19px', marginTop: 25, marginBottom: 50 }}>
							<span style={{ color: '#B1B4B3' }}>
								1. Karageorgiou V, Kaplan D. Porosity of 3D biomaterial scaffolds and osteogenesis.
								Biomaterials. 2005 Sep;26(27):5474-91<br />
								2. PROJ0000054457 | Tritanium C subsidence marketing memo<br />
								3. PROJ42624 | Tritanium PL subsidence memo<br />
								4. PROJ0000054459 | Tritanium C implant imaging marketing memo<br />
								5. PROJ0000054458 | Tritanium C insertion and expulsion marketing memo<br />
								6. PROJ44960 | Coefficient of friction memo.<br/>
								7. Stryker data on file; DHF 42351 Tritanium PL 
							</span>
							<br />
							<br />
							Refer to the Monterey AL, Tritanium PL, Tritanium TL, and Tritanium C surgical techniques
							and instructions for use for complete product information<br />
							<br />
							This website is intended for physicians only. It is not intended for patients. If you are a
							patient, you should not rely on the information on this website and should speak to your
							doctor about whether spinal fusion surgery is right for you.<br />
							<br />
							A surgeon must always rely on his or her own professional clinical judgment when deciding whether to use a particular product when treating a particular
patient. Stryker does not dispense medical advice and recommends that
surgeons be trained in the use of specific products before using them in surgery.
<br/><br/>
The information presented is intended to demonstrate the breadth of Stryker product offerings. A surgeon must always refer to the package insert, product label and/or instructions for use before using any Stryker product. Products may not be available in all markets because product availability is subject to the regulatory and/or medical practices in individual markets. Please contact your Stryker representative if you have questions about the availability of Stryker products in your area.
							<br /><br/>
							Stryker Corporation or its divisions or other corporate affiliated entities own, use or have
							applied for the following trademarks or service marks: AMagine, Built to fuse,Stryker,
							Tritanium. All other trademarks are trademarks of their respective owners or holders.<br />
							<br />
							TRITA-WB-2_Rev 3_34241 <br />
							<br />
							Copyright &copy; 2022 Stryker
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Product4;
