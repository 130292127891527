import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';

import SubNavTagline from '../components/SubNavTagline';
import TritaniumPL from '../assets/images/Tritanium_PL_Fig_034_ImplantViews_a-Clipped.png';
import FootprintGraph from '../assets/images/Footprint Graph.png';
import CT from '../assets/images/CT.png';
import Xray from '../assets/images/X-ray.png';
import MeasuredForceDirectionGraph from '../assets/images/Measured Force Direction Graph.png';
import ThreadedInserterConnectionImage from '../assets/images/Threaded Inserter Connection Image.png';
import ProductHighlights from '../assets/images/Product Highlights.png';

import TechnicalSalesSheet from '../assets/pdf/TRITA-SS-1 Technical Sell Sheet FINAL.pdf';
import TechnicalSummary from '../assets/pdf/TRITA_BR_3.pdf';
import CommercialBrochure from '../assets/pdf/TRITA-BR-1_Singlepage.pdf';
import Video from '../assets/pdf/TritaniumPLCage_H264_05-04-16.mp4'


class Product2 extends React.Component {
	state = {
		toggleVideo: false
	};

	toggleVideo = ()=>{
		const {toggleVideo} = this.state;
		this.setState({toggleVideo: !toggleVideo})

	}
	render() {
		const {toggleVideo} = this.state
		console.log(toggleVideo);
		return (
			<div className="ProductPage">
				<SubNavTagline
					tagline={
						<span className="egyptienne">
							Information for <strong>healthcare professionals</strong>
						</span>
					}
				/>
				<hr />

				<div className="mainSection" style={{ position: 'relative' }}>
					<div className="contentContainer">
						<div className="flex-ac mt-75">
							<div style={{ maxWidth: 500, marginRight: 120 }}>
								<div className="egyptienne mainTitle">
									More than <strong>surface deep</strong>
								</div>
								<h3 className="mt-15 egyptienne">
									<strong className="gold">
									Tritanium<sup>&reg;</sup> PL
									</strong>
									<br />
									Posterior Lumbar Cage
								</h3>
							</div>
							<img style={{ width: 422 }} alt="" src={TritaniumPL} />
						</div>
						<hr className="mt-75" />
					</div>
				</div>
				<div className="section mt-75">
					<div className="contentContainer">
						<div className="fs-25">
							<strong>The interconnected pores of the Tritanium PL Cage run endplate to endplate.</strong>
						</div>
						<p className="mt-20">
							The Tritanium PL Cage is a hollow, rectangular implant that consists of a unique
							configuration of both solid and porous structures, which are simultaneously built using 3D
							Additive Manufacturing applying our proprietary Tritanium In-Growth Technology.
						</p>
						<div className="primaryButton mt-25" onClick={()=>{
							this.toggleVideo()

						}}>TITANIUM PL CAGE VIDEO</div>
						<div className="flex mt-75" style={{ justifyContent: 'space-between' }}>
							<div style={{ maxWidth: 495, width: '100%' }}>
								<h2 className="egyptienne">
									Developed to<br />
									<strong className="gold">
										minimize subsidence<sup>1</sup>
									</strong>
								</h2>
								<p className="mt-20">
									The Tritanium PL Cage demonstrated better resistance to subsidence than other
									commercially available posterior lumbar interbody cages constructed out of different
									materials, including those with a larger footprint.<sup>1</sup>
								</p>

								<div className="bulletPoint mt-20">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Porous Tritanium has an elastic modulus that falls between cancellous and
										cortical bone<sup>1</sup>
									</p>
								</div>
								<div className="bulletPoint mt-20">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Large central graft and lateral windows help to reduce the overall stiffness of
										the cage and allow room for bone graft to be packed inside the cage<sup>1</sup>
									</p>
								</div>
								<div className="bulletPoint mt-20">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Teeth are designed to increase the surface area of the device in contact with
										bone in order to help normalize load transmission and minimize subsidence<sup>1</sup>
									</p>
								</div>
							</div>
							<div>
								<img alt="" src={FootprintGraph} style={{ width: 495, marginTop: 59 }} />
								<div style={{ marginTop: 42 }}>
									<div className="flex-ac">
										<i className="las la-file-alt" style={{ fontSize: 29, marginRight: 12.5 }} />
										<div className="fs-12 pointer" style={{ marginRight: 10 }} onClick={()=>window.open(TechnicalSalesSheet, '_blank')}>
											<strong className="gold">TECHNICAL SALES SHEET</strong>
										</div>
										<div className="arrow-right" />
									</div>
									<hr style={{ marginTop: 17 }} />
								</div>
								<div style={{ marginTop: 17 }}>
									<div className="flex-ac">
										<i className="las la-file-alt" style={{ fontSize: 29, marginRight: 12.5 }} />
										<div className="fs-12 pointer" style={{ marginRight: 10 }} onClick={()=>window.open(TechnicalSummary, '_blank')}>
											<strong className="gold">TECHNICAL SUMMARY</strong>
										</div>
										<div className="arrow-right" />
									</div>
									<hr style={{ marginTop: 17 }} />
								</div>
								<div style={{ marginTop: 17 }}>
									<div className="flex-ac">
										<i className="las la-file-alt" style={{ fontSize: 29, marginRight: 12.5 }} />
										<div className="fs-12 pointer" style={{ marginRight: 10 }} onClick={()=>window.open(CommercialBrochure, '_blank')}>
											<strong className="gold">COMMERCIAL BROCHURE</strong>
										</div>
										<div className="arrow-right" />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="gradientSection2 mt-75">
					<div className="contentContainer">
						<div className="sectionSplit flex" style={{ justifyContent: 'space-between' }}>
							<div className="flex-ac" style={{ margin: 'auto' }}>
								<img className="gradientImages" alt="" src={CT} style={{ marginRight: 45 }} />
								<img className="gradientImages" alt="" src={Xray} />
							</div>
							<div className="white" style={{ maxWidth: 514, width: '100%' }}>
								<h2 className="egyptienne">
									Created to allow <strong className="gold">imaging</strong>
								</h2>
								<p className="mt-25">
									With large lateral windows, Tritanium PL allows<br /> visualization on CT and X-ray.
								</p>
								<div style={{ fontSize: 10 }} className="mt-10">
									Images taken from a cadaveric study<sup>2</sup>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="section">
					<div className="contentContainer">
						<div className="flex mt-75" style={{ justifyContent: 'space-between' }}>
							<div style={{ width: 495 }}>
								<h2 className="egyptienne">
									Engineered for{' '}
									<strong className="gold">
										stability<sup>3,4</sup>
									</strong>
								</h2>
								<p className="mt-25">
									The precisely angled teeth of the Tritanium PL Cage are designed to allow
									bidirectional stability, with an expulsion force that was shown to be 22% greater
									than the insertion force.<sup>3</sup>
								</p>

								<div className="bulletPoint mt-20">
									<div className="gold">&bull;&nbsp;</div>
									<p>Teeth with a smooth leading edge are designed to aid movement across the vertebral endplates</p>
								</div>
								<div className="bulletPoint mt-20">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										High coefficient of friction for initial stability<sup>4</sup>
									</p>
								</div>
							</div>
							<img
								className="mt-75"
								alt=""
								src={MeasuredForceDirectionGraph}
								style={{ width: 493, height: '100%' }}
							/>
						</div>
						<div className="floatingBox2 mt-75">
							<img alt="" src={ThreadedInserterConnectionImage} style={{ width: 403, height: '100%' }} />
							<div style={{ width: 425 }}>
								<h2 className="egyptienne">
									Threaded inserter <strong className="gold">connection</strong>
								</h2>
								<p className="mt-20">
									The threaded inserter connection allows for strong, rigid connectivity and control
									during insertion.
								</p>
							</div>
						</div>
						<img className="mt-75" alt="" src={ProductHighlights} style={{ width: '100%' }} />
					</div>
				</div>

				<div className="section mt-75">
					<div className="contentContainer">
						<h2 className="egyptienne">
							Tritanium PL Cage <strong className="gold">technical data</strong>
						</h2>
						<hr className="mt-40" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
							Tritanium material
							</p>
							<p style={{ width: '50%' }}>Titanium alloy</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
							Tritanium mean porosity range<sup>5</sup>
							</p>
							<p style={{ width: '50%' }}>55 - 65%</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
							Tritanium mean pore size range<sup>5</sup>
							</p>
							<p style={{ width: '50%' }}>400 - 500μm</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
							Tritanium pore size range<sup>5</sup>
							</p>
							<p style={{ width: '50%' }}>100 - 700μm</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
							Tritanium pore interconnectedness
							</p>
							<p style={{ width: '50%' }}>Full endplate to endplate</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
								Lengths
							</p>
							<p style={{ width: '50%' }}>23, 28, and 32mm</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
								Widths
							</p>
							<p style={{ width: '50%' }}>9 and 11 mm</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
								Heights
							</p>
							<p style={{ width: '50%' }}>7 - 14mm</p>
						</div>
						<hr className="mt-75" />
						<div style={{ fontSize: 11, lineHeight: '19px', marginTop: 25, marginBottom: 50 }}>
							<span style={{ color: '#B1B4B3' }}>
								1. Subsidence memo PROJ42624.<br />
								2. Tritanium PL Cage cadaveric image folder / Stryker document #0000047030.<br />
								3. Insertion/expulsion memo PROJ42623.<br />
								4. Coefficient of friction memo PROJ44960.<br/>
								5. Stryker data on file; DHF 42351 Tritanium PL
							</span>
							<br />
							<br />
							Refer to the Monterey AL, Tritanium PL, Tritanium TL, and Tritanium C surgical techniques
							and instructions for use for complete product information<br />
							<br />
							This website is intended for physicians only. It is not intended for patients. If you are a
							patient, you should not rely on the information on this website and should speak to your
							doctor about whether spinal fusion surgery is right for you.<br />
							<br />
							A surgeon must always rely on his or her own professional clinical judgment when deciding whether to use a particular product when treating a particular
patient. Stryker does not dispense medical advice and recommends that
surgeons be trained in the use of specific products before using them in surgery.
<br/><br/>
The information presented is intended to demonstrate the breadth of Stryker product offerings. A surgeon must always refer to the package insert, product label and/or instructions for use before using any Stryker product. Products may not be available in all markets because product availability is subject to the regulatory and/or medical practices in individual markets. Please contact your Stryker representative if you have questions about the availability of Stryker products in your area.
							<br /><br/>
							Stryker Corporation or its divisions or other corporate affiliated entities own, use or have
							applied for the following trademarks or service marks: AMagine, Built to fuse,Stryker,
							Tritanium. All other trademarks are trademarks of their respective owners or holders.<br />
							<br />
							TRITA-WB-2_Rev 3_34241 <br />
							<br />
							Copyright &copy; 2022 Stryker
						</div>
					</div>
				</div>
				{toggleVideo &&
				<Modal isOpen={toggleVideo} toggle={this.toggleVideo}>
				
					<ModalBody className="modalBody" >
					<video style={{width: '100%'}} controls>
						<source src={Video} type="video/mp4"/>
					</video>
					<div style={{margin: 'auto'}} onClick={this.toggleVideo} className="primaryButton mt-25">CLOSE</div>

				</ModalBody>

				
			</Modal> }
			</div>
		);
	}
}

export default Product2;
