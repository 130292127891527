import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Dropdown,
	UncontrolledAccordion,
	AccordionItem,
	AccordionHeader,
	AccordionBody,
	UncontrolledDropdown
} from 'reactstrap';
import StrykerLogo from '../assets/images/Stryker Logo.svg';

const activeClassName = 'selected';

class Navbar extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.onMouseEnter = this.onMouseEnter.bind(this);
		this.onMouseLeave = this.onMouseLeave.bind(this);
		this.state = {
			dropdownOpen: false
		};
	}

	toggle() {
		this.setState((prevState) => ({
			dropdownOpen: !prevState.dropdownOpen
		}));
	}

	onMouseEnter() {
		this.setState({ dropdownOpen: true });
	}

	onMouseLeave() {
		this.setState({ dropdownOpen: false });
	}

	render() {
		return (
			<div className="Navbar">
				<div className="contentContainer">
					<div className="navContent">
						<Link to="/">
							<img className="navbarLogo" alt="logo" src={StrykerLogo} />
						</Link>
						<div className="navbarLinks">
							<NavLink
								className={({ isActive }) => `navbarLink ${isActive ? activeClassName : ''}`}
								to="/"
							>
								TRITANIUM STORY
							</NavLink>
							<Dropdown
								onMouseOver={this.onMouseEnter}
								onMouseLeave={this.onMouseLeave}
								isOpen={this.state.dropdownOpen}
								toggle={this.toggle}
							>
								<DropdownToggle className="navdropdown">
									<NavLink
										className={({ isActive }) => `navbarLink ${isActive ? activeClassName : ''}`}
										onClick={(e) => {
											e.preventDefault();
										}}
										to="/products"
									>
										PRODUCT PORTFOLIO
									</NavLink>
								</DropdownToggle>
								<DropdownMenu>
									<Link className="navbarDropdownLink" to="/products/monterey-al">
										<DropdownItem>MONTEREY AL</DropdownItem>
									</Link>
									<Link className="navbarDropdownLink" to="/products/tritanium-pl">
										<DropdownItem>TRITANIUM PL</DropdownItem>
									</Link>
									<Link className="navbarDropdownLink" to="/products/tritanium-tl">
										<DropdownItem>TRITANIUM TL</DropdownItem>
									</Link>
									<Link className="navbarDropdownLink" to="/products/tritanium-c">
										<DropdownItem>TRITANIUM C</DropdownItem>
									</Link>
								</DropdownMenu>
							</Dropdown>

							<div
								className="navButton"
								style={{ marginLeft: 25 }}
								onClick={() => window.open('mailto:spinemarcomm@stryker.com', '_blank')}
							>
								CONTACT US
							</div>
						</div>
					</div>
					<div className="mobileNavContent">
						<Link to="/">
							<img className="navbarLogo" alt="logo" src={StrykerLogo} />
						</Link>
						<UncontrolledDropdown>
							<DropdownToggle className="navdropdown">
								<div>
									<i className="las la-bars gold fs-25" />
								</div>
							</DropdownToggle>
							<div className="mobileNavbarLinks">
								<div className="contentContainer">
									<NavLink
										className={({ isActive }) => `navbarLink ${isActive ? activeClassName : ''}`}
										to="/"
									>
										<DropdownItem>TRITANIUM STORY</DropdownItem>
									</NavLink>
									<div className="navbarLink" style={{ paddingTop: 10, paddingBottom: 10 }}>
										PRODUCT PORTFOLIO
									</div>

									<NavLink
										className={({ isActive }) =>
											`navbarDropdownLink ${isActive ? activeClassName : ''}`}
										to="/products/monterey-al"
									>
										<DropdownItem>MONTEREY AL</DropdownItem>
									</NavLink>
									<NavLink
										className={({ isActive }) =>
											`navbarDropdownLink ${isActive ? activeClassName : ''}`}
										to="/products/tritanium-pl"
									>
										<DropdownItem>TRITANIUM PL</DropdownItem>
									</NavLink>
									<NavLink
										className={({ isActive }) =>
											`navbarDropdownLink ${isActive ? activeClassName : ''}`}
										to="/products/tritanium-tl"
									>
										<DropdownItem>TRITANIUM TL</DropdownItem>
									</NavLink>
									<NavLink
										className={({ isActive }) =>
											`navbarDropdownLink ${isActive ? activeClassName : ''}`}
										to="/products/tritanium-c"
									>
										<DropdownItem>TRITANIUM C</DropdownItem>
									</NavLink>
									<NavLink
										className={({ isActive }) => `navbarLink ${isActive ? activeClassName : ''}`}
										to="/about"
									>
										<DropdownItem>NEWS & EVENTS</DropdownItem>
									</NavLink>
									<div className="navButton mt-20 mb-24" onClick={() => window.open('mailto:')}>
										{' '}
										<DropdownItem>CONTACT US</DropdownItem>
									</div>
								</div>
							</div>
						</UncontrolledDropdown>
					</div>
				</div>
			</div>
		);
	}
}

export default Navbar;
