import React from 'react';
import { Link } from 'react-router-dom';
import SubNavTagline from '../components/SubNavTagline';
import Tritanium1 from '../assets/images/Tritanium1.png';
import Tritanium2 from '../assets/images/Tritanium2.png';
import TritaniumC from '../assets/images/Tritanium C Image.png';
import TritaniumTL from '../assets/images/Tritanium TL Image.png';
import TritaniumPL from '../assets/images/Tritanium PL Image.png';
import MontereyAL from '../assets/images/Monterey AL Image.png';
import BoneTritanium from '../assets/images/BoneTritanium_CMYK.png';
import AMagine from '../assets/images/AMagine Image.png';
import WeekTri from '../assets/images/16WeekTri.png';
import BuiltToAttachAndMultiply from '../assets/images/BuiltToAttachAndMultiply Image.png';
import BuiltToMimic from '../assets/images/BuiltToMimic Image.png';
import BuiltToWick from '../assets/images/BuiltToWick Image.png';
import BuiltToFuse from '../assets/images/BuiltToFuse Image.png';
import TritaniumPanel from '../assets/images/Tritanium_Panel D.png';
import InGrowthTechnology from '../assets/images/Tritanium In-Growth Technology Image.png';
import CancellousBone from '../assets/images/Cancellous Bone Image.png';
import TritaniumComparionGrid from '../assets/images/Tritanium Comparion Grid.png';
import TritaniumCrossSection from '../assets/images/Tritanium cross section.png';
import CrossSectionComparison from '../assets/images/cross section comparison image.png';
import StemCellOsteogenicActivity from '../assets/images/Stem Cell Osteogenic Activity Image.png';
import InflammationGraph from '../assets/images/Inflammation Graph.png';
import BiomechanicalGraph from '../assets/images/Biomechanical Graph.png';
import HistologyImage from '../assets/images/Histology Image.png';
import HistologyGraph from '../assets/images/Histology Graph.png';
import TissueComparisonSection from '../assets/images/Tissue comparison section.png';
import InstalledImageSection from '../assets/images/Installed image section.png';
import PLSheepStudy from '../assets/images/PL Sheep Study.png';
import EuroSpineJournal from '../assets/images/Euro Spine Journal.png';
import OnDemandPodiumPresentations from '../assets/images/On Demand Podium Presentations.png';
import Arrows from '../assets/images/Arrows.png';
import CTImage6 from '../assets/images/6 month CT Image.png';
import CTImage2 from '../assets/images/2.5 month CT Image.png';
import Graph from '../assets/images/Graph.png';
import Katie from '../assets/images/KatiePowers.png';
import Andrea from '../assets/images/AndreaSampson.png';
import FluidRetentionVsMaterialAndFootprint from '../assets/images/FluidRetentionVsMaterialAndFootprint.png';
import ContentAccordion from '../components/ContentAccordion';

import PEEKStudy from '../assets/pdf/Clinical and cost-effectiveness of lumbar interbody fusion using Tritanium posterolateral cage vs. propensity-matched cohort of PEEK cage.pdf';
import BonyIngrowth from '../assets/pdf/Bony ingrowth potential of 3D-printed porous titanium alloy - McGilvray et all - The Spine Journal Tritanium paper.pdf';
class Home extends React.Component {
	render() {
		return (
			<div className="HomePage">
				<SubNavTagline
					tagline={
						<span className="egyptienne">
							Information for <strong>healthcare professionals</strong>
						</span>
					}
				/>
				<hr style={{ margin: 0 }} />
				<div className="mainSection" style={{ position: 'relative' }}>
					<div className="contentContainer">
						<div className="flex-ac mainTitle">
							<strong>Tritanium</strong>
							<div className="reg-icon">R</div>
						</div>
						<h3 className="egyptienne">
							In-Growth Technology<sup>1</sup>
						</h3>
						<h3 className="futura-bold gold">
							Built to fuse<sup className="futura-bold">&trade;</sup>
						</h3>
						<div
							style={{ marginTop: 23 }}
							className="primaryButton"
							onClick={() => window.open('mailto:spinemarcomm@stryker.com', '_blank')}
						>
							REQUEST MORE INFORMATION
						</div>
					</div>
					<img
						className="tritanium-image2"
						style={{ maxHeight: 400, position: 'absolute', right: 0, zIndex: -1 }}
						alt=""
						src={Tritanium2}
					/>
					<img
						className="tritanium-image1"
						style={{ maxHeight: 100, position: 'absolute', bottom: 0, left: 0, zIndex: -1 }}
						alt=""
						src={Tritanium1}
					/>
				</div>
				<div className="section">
					<div className="contentContainer" style={{ marginTop: 75 }}>
						<h2 className="egyptienne">
							<strong>Inspired by biology. Enabled by design.</strong>
						</h2>
						<p>
							Through precise computer modeling and AMagine manufacturing technology, Tritanium In-Growth
							Technology is designed to mimic cancellous bone and provide an environment favorable to bone
							regeneration and fusion.<sup>1-5</sup>
						</p>
						<div className="productGrid">
							<Link className="gridProduct" to="/products/monterey-al">
								<img alt="" src={MontereyAL} />
								<div style={{ marginLeft: 15 }}>
									<div className="egyptienne fs-26">
										Fixated on <strong>science</strong>
									</div>
									<div className="futura-bold fs-18">Monterey AL</div>
									<div className="egyptienne fs-18">Anterior Lumbar Cage</div>
								</div>
							</Link>
							<Link className="gridProduct" to="/products/tritanium-pl" style={{ paddingLeft: 0 }}>
								<img alt="" src={TritaniumPL} />
								<div style={{ marginLeft: 15 }}>
									<div className="egyptienne fs-26">
										<strong>More than</strong> surface deep
									</div>
									<div className="futura-bold fs-18">Tritanium PL</div>
									<div className="egyptienne fs-18">Posterior Lumbar Cage</div>
								</div>
							</Link>
							<Link className="gridProduct" to="/products/tritanium-tl" style={{ paddingLeft: 0 }}>
								<img alt="" src={TritaniumTL} />
								<div style={{ marginLeft: 15 }}>
									<div className="egyptienne fs-26">
										<strong>Redefining</strong> steerability
									</div>
									<div className="futura-bold fs-18">Tritanium TL</div>
									<div className="egyptienne fs-18">Curved Posterior Lumbar Cage</div>
								</div>
							</Link>
							<Link className="gridProduct" to="/products/tritanium-c" style={{ paddingLeft: 0 }}>
								<img alt="" src={TritaniumC} />
								<div style={{ marginLeft: 15 }}>
									<div className="egyptienne fs-26">
										Engineered <strong>for bone</strong>
									</div>
									<div className="futura-bold fs-18">Tritanium C</div>
									<div className="egyptienne fs-18">Anterior Cervical Cage</div>
								</div>
							</Link>
						</div>
					</div>
				</div>
				<div className="section" style={{ marginTop: 50 }}>
					<img className="pictureBanner" alt="" src={BoneTritanium} />
				</div>
				<div className="section" style={{ marginTop: 75 }}>
					<div className="contentContainer">
						<h2 className="egyptienne">
							The <strong>Tritanium</strong> story
						</h2>
					</div>
				</div>
				<div className="section" style={{ marginTop: 40 }}>
					<div className="contentContainer">
						<hr className="m-0" />
						<ContentAccordion
							title={
								<div>
									<div className="fs-29 egyptienne">
										Built with{' '}
										<strong className="gold">
											AMagine<sup className="futura-bold">6</sup>
										</strong>{' '}
									</div>
									<p>
										20+ years of leadership in precision manufacturing of complex geometries such as
										Tritanium In-Growth Technology
									</p>
								</div>
							}
							titleImage={AMagine}
						>
							<div style={{ marginBottom: 24 }}>
								Our proprietary AMagine additive manufacturing (AM) process - sometimes called 3D
								printing or laser rapid manufacturing - has made us a global leader in additive
								manufactured spinal implants. Here's what it means for you.
								<div className="bulletPoint" style={{ marginTop: 25 }}>
									<div className="gold">&bull;&nbsp;</div>Transforms bright ideas into market
									realities with potentially increased product development speed
								</div>
								<div className="mt-20 bulletPoint">
									<div className="gold">&bull;&nbsp;</div>Helps enable previously difficult or
									impossible to manufacture implant geometries
								</div>
								<div className="mt-20 bulletPoint">
									<div className="gold">&bull;&nbsp;</div>Differentiates porous matrix, shape and
									other surgical features specifically for each cage’s design
								</div>
								<div className="mt-20 bulletPoint">
									<div className="gold">&bull;&nbsp;</div>“Grows” parts layer-by-layer based on
									stringent computer modeling and incorporates 100+ quality checks per batch
								</div>
								<div className="mt-20 bulletPoint">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Designed to provide the high performance, reproducibility and quality you expect<sup>1</sup>
									</p>
								</div>
								<div className="darkContentBlock">
									<div className="fs-29 egyptienne white">
										<strong className="gold">AMagine</strong> the strength<sup>6</sup>
									</div>
									<p className="white">World's largest AM facility for orthopaedic implants</p>
									<div className="flex mt-25">
										<div>
											<div className="fs-19">
												<strong className="gold">Trusted worldwide in</strong>
											</div>
											<div className="fs-19 white egyptienne">
												<strong>750,000+</strong> orthopaedic implants<br />
												<strong>110,000+</strong> spine implants
											</div>
										</div>
										<div className="contentSeparator" />
										<div>
											<div className="fs-19 white">
												<strong className="gold">20+ years of AM research</strong>
												<br />
												and design expertise
											</div>
										</div>
									</div>
								</div>
							</div>
						</ContentAccordion>
						<hr className="m-0" />
						<ContentAccordion
							title={
								<div>
									<div className="fs-29 egyptienne">
										Built to{' '}
										<strong className="gold">
											mimic<sup className="futura-bold">2-4</sup>
										</strong>{' '}
									</div>
									<p>
										Tritanium is designed to mimic the porous structure of cancellous bone<sup>6-8</sup>
									</p>
								</div>
							}
							titleImage={BuiltToMimic}
						>
							<div>
								<h3 className="futura-bold">Porosity</h3>
								<div className="flex mt-15">
									<div>
										<p className="futura-bold gold">Cancellous bone</p>
										<img width={322} alt="" src={CancellousBone} className="mt-10" />
										<p className="humst-black mt-25">Cancellous bone characteristics</p>

										<div className="bulletPoint fs-12" style={{ marginTop: 5 }}>
											<div className="gold">&bull;&nbsp;</div>Avg pore diameter of cancellous bone<sup style={{ top: 5 }}>7</sup>{' '}
											= 600 µm
										</div>
										<div className="bulletPoint fs-12" style={{ marginTop: 6 }}>
											<div className="gold">&bull;&nbsp;</div>Avg porosity of cancellous bone<sup style={{ top: 5 }}>4</sup>{' '}
											= ~50-90%
										</div>
									</div>

									<div style={{ marginLeft: 6 }}>
										<p className="futura-bold gold">
											Tritanium In-Growth Technology<sup>1</sup>
										</p>
										<img width={322} alt="" src={InGrowthTechnology} className="mt-10" />
										<p className="humst-black mt-25">
											Tritanium material characteristics<sup>2,3</sup>
										</p>

										<div className="bulletPoint fs-12" style={{ marginTop: 5 }}>
											<div className="gold">&bull;&nbsp;</div>Randomized pore sizing designed to
											mimic cancellous bone<sup style={{ top: 5 }}>1</sup>
										</div>
										<div className="bulletPoint fs-12" style={{ marginTop: 6 }}>
											<div className="gold">&bull;&nbsp;</div>Interconnected pore structure from
											endplate to endplate<sup style={{ top: 5 }}>3</sup>
										</div>
										<div className="bulletPoint fs-12" style={{ marginTop: 6, marginLeft: 15 }}>
											<div className="gold">&bull;&nbsp;</div>Pore size range: 100-700 μm
										</div>
										<div className="bulletPoint fs-12" style={{ marginTop: 6, marginLeft: 15 }}>
											<div className="gold">&bull;&nbsp;</div>Mean pore size range: 400-500 μm
										</div>
										<div className="bulletPoint fs-12" style={{ marginTop: 6, marginLeft: 15 }}>
											<div className="gold">&bull;&nbsp;</div>Mean porosity: 55-65%
										</div>
									</div>
								</div>
								<hr style={{ margin: '40px 0' }} />
								<h3 className="futura-bold">
									Porosity and pore size distribution<sup>8</sup>
								</h3>
								<div className="bulletPoint" style={{ marginTop: 15 }}>
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Cancellous bone has randomized, non-uniform, interconnected porosity comprised
										of a wide range of pore sizes that serve various biological functions and
										contribute in concert to the overall biological and mechanical function of the
										tissue<sup>9</sup>
									</p>
								</div>
								<div className="mt-10 bulletPoint">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Tritanium is designed to be similar to cancellous bone in total % porosity and
										pore size distribution<sup>9</sup>
									</p>
								</div>
								<div className="mt-10 bulletPoint">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Small pores allow for capillary action<sup>4,10</sup>
									</p>
								</div>
								<div className="mt-10 bulletPoint" style={{ marginLeft: 25 }}>
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Built to <strong className="gold">wick</strong>
									</p>
								</div>
								<div className="mt-10 bulletPoint">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Medium sized pores allow for cell infiltration<sup>11</sup>
									</p>
								</div>
								<div className="mt-10 bulletPoint" style={{ marginLeft: 25 }}>
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Designed to create a <strong className="gold">favorable environment</strong> for
										cells
									</p>
								</div>
								<div className="mt-10 bulletPoint">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Larger pores allow for bone formation and vascularization<sup>4</sup>
									</p>
								</div>
								<div className="mt-10 bulletPoint" style={{ marginLeft: 25 }}>
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Designed for <strong className="gold">in-growth</strong>
									</p>
								</div>
								<hr style={{ marginTop: 40, marginBottom: 0 }} />
								<div className="flex-ac" style={{ marginTop: 15, marginBottom: 15 }}>
									<p className="charTableLeft">
										<strong>Cancellous Allograft</strong>
									</p>
									<p className="charTableRight">
										<strong className="gold">50-90% porosity<sup >8</sup></strong>
									</p>
								</div>
								<hr className="m-0" />
								<div className="flex-ac" style={{ marginTop: 15, marginBottom: 15 }}>
									<p className="charTableLeft">
										<strong>Tritanium</strong>
									</p>
									<p className="charTableRight">
										<strong className="gold">55-65% porosity<sup >8</sup></strong>
									</p>
								</div>
								<hr className="m-0" />
								{/* 								<img alt="" src={Graph} width="100%" style={{ marginTop: 40, marginBottom: 24 }} />
 */}{' '}
							</div>
						</ContentAccordion>
						<hr className="m-0" />
						<ContentAccordion
							title={
								<div>
									<div className="fs-29 egyptienne">
										Built to{' '}
										<strong className="gold">
											wick<sup className="futura-bold">12,13*</sup>
										</strong>{' '}
									</div>
									<p>
										Tritanium material may be able to wick or retain fluid in comparison to
										traditional titanium material<sup>12,13</sup>
									</p>
								</div>
							}
							titleImage={BuiltToWick}
						>
							<div className="mb-24">
								<iframe
									alt=""
									title="Built to wick"
									src="https://player.vimeo.com/video/214076798"
									width="100%"
									height="350px"
								/>

								<div
									style={{
										color: '#B1B4B3',
										fontSize: 10,
										marginTop: 5,
										height: '100%',
										float: 'right'
									}}
								>
									RD 50927 | Tritanium Wicking Evaluation
								</div>

								<p className="futura-bold mt-40">
									Tritanium C Cage absorbed 3 times more bone marrow aspirate (BMA) than allograft and
									4 times more BMA than PEEK in an in vitro study<sup>14</sup>
									<img
										alt=""
										src={FluidRetentionVsMaterialAndFootprint}
										style={{ width: '100%' }}
										className="mt-25"
									/>
								</p>
								<div className="flex mt-25 mb-24" style={{ justifyContent: 'space-between' }}>
									<div style={{ color: '#B1B4B3', fontSize: 13 }}>
										*Tritanium material may be able to wick or retain fluid in comparison to
										traditional titanium material. This experiment was performed using heparinized
										porcine bone marrow aspirate. No correlation to human clinical outcomes has been
										demonstrated or established.
									</div>
								</div>
							</div>
						</ContentAccordion>
						<hr className="m-0" />
						<ContentAccordion
							title={
								<div>
									<div className="fs-29 egyptienne">
										Built to {' '}
										<strong className="gold">
											attach and multiply<sup className="futura-bold">15,16</sup>
										</strong>{' '}
									</div>
									<p>
										Bone cells attached, infiltrated, and proliferated within Tritanium's porosity<sup>15,16*</sup>
									</p>
								</div>
							}
							titleImage={BuiltToAttachAndMultiply}
						>
							<div>
								<p>
									A coupon built with Tritanium In-Growth Technology demonstrated that osteoblasts
									(bone cells) infiltrated, attached to and proliferated throughout the porosity of
									the Tritanium technology.<sup>15,16*</sup>
									<br />
									<br />The unique porous structure is{' '}
									<span className="gold humst-black">
										designed to create a favorable environment for cell attachment.<sup>4,15</sup>
									</span>
								</p>
								<img className="mt-25" style={{ width: '100%' }} alt="" src={TritaniumComparionGrid} />
								<div className="flex mt-25 mb-24" style={{ justifyContent: 'space-between' }}>
									<div style={{ color: '#B1B4B3', fontSize: 13, width: 317 }}>
										* Image depicts a sample built with Tritanium Technology used for in vitro cell
										studies. The sample was designed to mimic a generic interbody cage with an open
										graft window. This is not an implantable device.
									</div>
									<img style={{ width: 210, marginRight: 50 }} alt="" src={TritaniumCrossSection} />
								</div>
								<div style={{ color: '#B1B4B3', fontSize: 13 }}>
									* No correlation to human clinical outcomes has been demonstrated or established.
								</div>
							</div>
						</ContentAccordion>
						<hr className="m-0" />
						<ContentAccordion
							title={
								<div>
									<div className="fs-29 egyptienne">
										Built to {' '}
										<strong className="gold">
											differentiate<sup className="futura-bold">17,18</sup>
										</strong>{' '}
									</div>
									<p>
										Stem cells grown on Tritanium exhibited osteogenic Alkaline Phosphatase without
										requiring growth factor supplements<sup>17,18</sup>
									</p>
								</div>
							}
							titleImage={TritaniumPanel}
						>
							<div className="mb-24">
								<h3>
									<strong>
										Tritanium stem cell study<sup>17,18</sup>
									</strong>
								</h3>
								<p className="humst-black mt-25">Methodology</p>

								<div className="bulletPoint" style={{ marginTop: 9 }}>
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Evaluated impact of porous architecture and scaffold composition using{' '}
										<span className="humst-black gold">undifferentiated</span> stem cells
									</p>
								</div>

								<p className="humst-black mt-25">Results</p>

								<div className="bulletPoint" style={{ marginTop: 9 }}>
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Expression of Alkaline Phosphatase (ALP) by hMSC grown on a Tritanium coupon
										increased over time and was 4X greater than that by hMSC on solid Ti by day 14<sup>17,18</sup>
									</p>
								</div>

								<div className="bulletPoint mt-15">
									<div className="gold">&bull;&nbsp;</div>
									<p>Validates Tritanium's randomized porosity</p>
								</div>

								<div className="bulletPoint mt-15 ml-15">
									<div className="gold">&bull;&nbsp;</div>
									<p>Influenced the biological response</p>
								</div>
								<div className="bulletPoint mt-15 ml-15">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Induced <span className="humst-black gold">osteogenic</span> differentiation
										without requiring growth factor supplementation
									</p>
								</div>
								<div className="bulletPoint mt-15">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Peer-reviewed poster presentation at Philadelphia Spine Research Symposium<sup>18</sup>{' '}
										- 2019
									</p>
								</div>
								<div className="flex mt-40">
									<img
										alt=""
										src={StemCellOsteogenicActivity}
										style={{ width: '100%', maxWidth: 373, height: '100%', marginRight: 28 }}
									/>
									<div style={{ width: '100%', maxWidth: 248, height: '100%' }}>
										<img
											alt=""
											src={CrossSectionComparison}
											style={{ width: '100%', maxWidth: 248, height: '100%' }}
										/>
										<div
											style={{
												display: 'flex',
												justifyContent: 'space-between',
												maxWidth: 248,
												fontSize: 11
											}}
										>
											<p style={{ fontSize: 11 }}>Tritanium</p>
											<p style={{ fontSize: 11 }}>Solid Titanium</p>
											<p style={{ fontSize: 11 }}>PEEK</p>
										</div>
									</div>
								</div>
								<h3 className="mt-40">
									<strong>What does this mean?</strong>
								</h3>
								<div className="bulletPoint mt-15">
									<div className="gold">&bull;&nbsp;</div>
									<p className="humst-black">
										Indicates that Tritanium induced undifferentiated stem cells to produce the
										early osteogenic bone marker, ALP, without requiring growth factors, like BMP-2
									</p>
								</div>

								<div className="bulletPoint mt-15 ml-15">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Tritanium architecture is designed to mirror that of cancellous bone in total % porosity and
										pore size distribution<sup>19</sup>
									</p>
								</div>
								<div className="bulletPoint mt-15 ml-15">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Titanium is known to be bone-friendly<sup>20</sup> (ALP activity: Solid Ti {'>'}{' '}
										PEEK)
									</p>
								</div>
								<div className="bulletPoint mt-15 ml-15">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										When titanium is arranged in a configuration that mimics bone (Tritanium vs.
										solid Ti), osteogenic activity was enhanced
									</p>
								</div>
								<div className="bulletPoint mt-15">
									<div className="gold">&bull;&nbsp;</div>
									<p className="humst-black">
										Supports established literature that PEEK is less conducive to bone formation<sup>20</sup>
									</p>
								</div>
								<div className="bulletPoint mt-15 ml-15">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										PEEK showed the lowest ALP activity, followed by Solid Ti, with Tritanium
										highest
									</p>
								</div>
							</div>
							
						</ContentAccordion>
						<hr className="m-0" />
						<ContentAccordion
							title={
								<div>
									<div className="fs-29 egyptienne">
										Built for {' '}
										<strong className="gold">
											in-growth<sup className="futura-bold">21*</sup>
										</strong>{' '}
									</div>
									<p>
										Bone grew onto and into the porous structure of the Tritanium cage in a
										preclinical study
									</p>
								</div>
							}
							titleImage={WeekTri}
						>
							<div className="mb-24">
								<h3>
									<strong>
										Tritanium PL sheep study<sup>21,22</sup>
									</strong>
								</h3>

								<div className="bulletPoint mt-15">
									<div className="gold">&bull;&nbsp;</div>
									<p className="humst-black">Animal model - ovine lumbar fusion:</p>
								</div>

								<div className="bulletPoint mt-5 ml-15">
									<div className="gold">&bull;&nbsp;</div>
									<p>Two level (L2-L3 and L4-L5) lumbar fusion with iliac crest autograft</p>
								</div>
								<div className="flex mt-20">
									<div style={{ width: 230, marginRight: 41 }}>
										<div className="bulletPoint">
											<div className="gold">&bull;&nbsp;</div>
											<p className="humst-black">Groups:</p>
										</div>

										<div className="bulletPoint mt-5 ml-15">
											<div className="gold">&bull;&nbsp;</div>
											<p>Tritanium PL Cage</p>
										</div>
										<div className="bulletPoint mt-5 ml-15">
											<div className="gold">&bull;&nbsp;</div>
											<p>PEEK Cage</p>
										</div>
										<div className="bulletPoint mt-5 ml-15">
											<div className="gold">&bull;&nbsp;</div>
											<p>Plasma-sprayed Titanium-coated PEEK Cage (PSP)</p>
										</div>
										<div className="bulletPoint mt-10">
											<div className="gold">&bull;&nbsp;</div>
											<p className="humst-black">Time points:</p>
										</div>

										<div className="bulletPoint mt-5 ml-15">
											<div className="gold">&bull;&nbsp;</div>
											<p>8 weeks</p>
										</div>
										<div className="bulletPoint mt-5 ml-15">
											<div className="gold">&bull;&nbsp;</div>
											<p>16 weeks</p>
										</div>
										<div className="bulletPoint mt-10">
											<div className="gold">&bull;&nbsp;</div>
											<p className="humst-black">Analyses:</p>
										</div>

										<div className="bulletPoint mt-5 ml-15">
											<div className="gold">&bull;&nbsp;</div>
											<p>Histology</p>
										</div>
										<div className="bulletPoint mt-5 ml-15">
											<div className="gold">&bull;&nbsp;</div>
											<p>Biomechanics – Range of Motion / Stiffness</p>
										</div>
									</div>
									<div>
										<div className="floatingBox">
											<img alt="" src={PLSheepStudy} style={{ width: 302 }} />
										</div>
										<div
											style={{
												color: '#B1B4B3',
												width: 317,
												fontSize: 10,
												marginTop: 5,
												height: '100%'
											}}
										>
											* Correlation to human clinical outcomes has not been demonstrated or
											established
										</div>
									</div>
								</div>
								<div className="flex mt-25" style={{ justifyContent: 'center' }}>
									<div
										onClick={() => window.open(BonyIngrowth, '_blank')}
										rel="noreferrer"
										className="primaryButton"
										style={{ width: 168, textAlign: 'center', textDecoration: 'none' }}
									>
										VIEW STUDY
									</div>
								</div>
								<img className="mt-40" style={{ width: '100%' }} alt="" src={InstalledImageSection} />
								<img className="mt-40" style={{ width: '100%' }} alt="" src={TissueComparisonSection} />
								<hr className="mt-40" />
								<div className="fs-19 gold mt-40">
									<strong>Histology summary</strong>
								</div>
								<p className="humst-black mt-15">Bone content - total region of interest (ROI)</p>
								<div className="bulletPoint mt-15">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Tritanium: significant increase in{' '}
										<span className="humst-black gold">bone content</span> over time
									</p>
								</div>
								<div className="bulletPoint mt-15 ml-15">
									<div className="gold">&bull;&nbsp;</div>
									<p>This difference was not evident in PEEK and PSP</p>
								</div>
								<p className="humst-black mt-25">Bony bridging - semi-quantitative fusion assessment</p>
								<div className="bulletPoint mt-15">
									<div className="gold">&bull;&nbsp;</div>
									<p>Tritanium:</p>
								</div>
								<div className="bulletPoint mt-15 ml-15">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Significant increase in <span className="humst-black gold">bony bridging</span>{' '}
										score over time
									</p>
								</div>
								<div className="bulletPoint mt-15 ml-15">
									<div className="gold ml-15">&bull;&nbsp;</div>
									<p>This difference was not evident in PEEK and PSP</p>
								</div>

								<div className="bulletPoint mt-15 ml-15">
									<div className="gold">&bull;&nbsp;</div>
									<p>Significantly greater bony bridging score vs. PEEK and PSP at 16 weeks</p>
								</div>
								<div className="flex mt-25">
									<img alt="" src={HistologyGraph} style={{ width: 340, marginRight: 30 }} />
									<img alt="" src={HistologyImage} style={{ width: 279 }} />
								</div>
								<hr className="mt-40" />

								<div className="fs-19 gold mt-40">
									<strong>Biomechanical summary</strong>
								</div>
								<div className="flex mt-15">
									<div style={{ marginRight: 25, width: 395 }}>
										<p className="humst-black">Range of motion (ROM) | Tritanium PL Cage:</p>
										<div className="bulletPoint mt-15">
											<div className="gold">&bull;&nbsp;</div>
											<p>
												Demonstrated significant{' '}
												<span className="humst-black gold">decrease in ROM</span> over time in
												all three loading directions - axial rotation, flexion/extension, and
												lateral bending
											</p>
										</div>
										<div className="bulletPoint mt-10 ml-15">
											<div className="gold">&bull;&nbsp;</div>
											<p>This difference was not evident in PEEK and PSP</p>
										</div>
										<div className="bulletPoint mt-10">
											<div className="gold">&bull;&nbsp;</div>
											<p>
												Significantly <span className="humst-black gold">lower ROM</span> in
												flexion/extension vs PEEK at 16 weeks
											</p>
										</div>
										<div className="bulletPoint mt-10">
											<div className="gold">&bull;&nbsp;</div>
											<p>
												Tritanium PL Cage had the lowest ROM mean magnitude in all loading
												directions at 16 weeks
											</p>
										</div>
									</div>
									<img alt="" src={BiomechanicalGraph} style={{ width: 250, height: '100%' }} />
								</div>
								<hr className="mt-40" />
								<div className="fs-19 gold mt-40">
									<strong>Inflammation</strong>
								</div>
								<div className="flex mt-15">
									<div style={{ marginRight: 25, width: 395 }}>
										<div className="bulletPoint mt-15">
											<div className="gold">&bull;&nbsp;</div>
											<p>
												Utilizing data from Tri-PL sheep study<sup>21</sup>, compared
												inflammatory response for Tri-PL, PEEK, and plasma-sprayed PEEK
											</p>
										</div>
										<div className="bulletPoint mt-10">
											<div className="gold">&bull;&nbsp;</div>
											<p>
												Tri-PL was the only group to show a reduction in inflammation over time<sup>23</sup>
											</p>
										</div>
										<div className="bulletPoint mt-10">
											<div className="gold">&bull;&nbsp;</div>
											<p>
												By 16 weeks, Tri-PL inflammation was significantly lower than PEEK<sup>23</sup>
											</p>
										</div>
									</div>
									<img alt="" src={InflammationGraph} style={{ width: 242, height: '100%' }} />
								</div>
								{/*<hr className="mt-40" />
							 <div className="mt-15" style={{ color: '#B1B4B3', lineHeight: '15px', fontSize: 11 }}>
									* Correlation to human clinical outcomes has not been established<br />
									1. SRL 15-02 / Stryker -02-15 <br />
									2. McGilvray KC, et al. The Spine Journal (2018) 18: 1250-1260.
								</div> */}
							</div>
						</ContentAccordion>
						<hr className="m-0" />
						<ContentAccordion
							title={
								<div>
									<div className="fs-29 egyptienne">
										Built to {' '}
										<strong className="gold">
											fuse<sup className="futura-bold">1,5</sup>
										</strong>{' '}
									</div>
									<p>
										In a prospective clinical study, Tritanium PL showed higher fusion rates, better
										patient reported outcomes, lower subsidence, and lower indirect surgical costs
										versus the propensity-matched PEEK cohort.<sup>1,5</sup>{' '}
									</p>
								</div>
							}
							titleImage={BuiltToFuse}
						>
							<div className="mb-24">
								<h3>
									<strong>Early clinical cases</strong>
								</h3>
								<p className="mt-15">
									The Tritanium PL Cage is intended to be used with supplemental spinal fixation
									systems that have been cleared for use in the lumbosacral spine
								</p>
								<div className="flex mt-25">
									<div>
										<img
											style={{ width: 317, marginRight: 55, height: '100%' }}
											alt=""
											src={CTImage2}
										/>
										<div
											style={{
												color: '#B1B4B3',
												width: 317,
												fontSize: 10,
												marginTop: 5,
												height: '100%'
											}}
										>
											Case source: Charles Sansur, MD, FAANS, University of Maryland, Baltimore,
											MD, USA
										</div>
									</div>
									<div>
										<img style={{ width: 277, height: '100%' }} alt="" src={CTImage6} />

										<div
											style={{
												color: '#B1B4B3',
												width: 277,
												fontSize: 10,
												marginTop: 5,
												height: '100%'
											}}
										>
											Case source: Alan H. Daniels, MD, University of Orthopedics, Providence, RI,
											USA
										</div>
									</div>
								</div>
								<hr className="mt-40" />
								<h3 className="mt-40">
									<strong>Recent peer-reviewed clinical data</strong>
								</h3>
								<div className="fs-19 mt-25">
									<strong className="gold">
										Dr. Devin - Clinical study<sup>5</sup>
									</strong>
								</div>
								<p className="mt-15">
									In a 228-patient study, 114 patients who underwent elective lumbar interbody fusion
									surgery for degenerated disc-related diseases received the Tritanium PL implant.
									These patients were compared against a propensity-matched historic PEEK cohort.
									<br />
									<br />
									Both the Tritanium and PEEK groups exhibited a significant improvement from baseline
									measurements in patient reported outcomes at 3- and 12-months. The Tritanium group
									showed significant improvements in ODI (Oswestry Disability Index) versus PEEK at
									both 3- and 12-months (p=0.013 at 3 months, p=0.048 at 12 months). At 12-months, radiologic review showed intact fusion of the
									operative levels in 90% of the Tritanium cohort versus 73% fusion in the PEEK group
									and a reduced occurrence of subsidence in Tritanium vs. PEEK. While there were no
									measured differences between groups in return-to-work or direct cost of surgical
									care, the indirect cost of care was found to be significantly lower for the
									Tritanium cohort vs. PEEK. (p=0.006)
									<br />
									<br />
									This study indicates that Tritanium can be an effective alternative to PEEK in the
									treatment of lumbar degenerative disc diseases.
								</p>
								<img className="mt-25" style={{ width: '100%' }} alt="" src={Arrows} />
								<div className="flex mt-25" style={{ justifyContent: 'center' }}>
									<div
										rel="noreferrer"
										onClick={() => window.open(PEEKStudy, '_blank')}
										target="_blank"
										className="primaryButton"
										style={{ width: 168, textAlign: 'center', textDecoration: 'none' }}
									>
										VIEW STUDY
									</div>
								</div>
								<hr style={{ marginTop: 56 }} />
								<img
									className="mt-40"
									style={{ width: '100%' }}
									alt=""
									src={OnDemandPodiumPresentations}
								/>
								<img className="mt-40" style={{ width: '100%' }} alt="" src={EuroSpineJournal} />
								<div className="flex mt-25" style={{ justifyContent: 'center' }}>
									<div
										rel="noreferrer"
										onClick={() =>
											window.open(
												'https://link.springer.com/article/10.1007/s00586-020-06673-4',
												'_blank'
											)}
										target="_blank"
										className="primaryButton"
										style={{ width: 168, textAlign: 'center', textDecoration: 'none' }}
									>
										VIEW STUDY
									</div>
								</div>
							</div>
						</ContentAccordion>
						<hr className="m-0" />
					</div>
				</div>

				<div className="section mt-75">
					<div className="contentContainer">
						<h2 className="egyptienne">
							Material characteristics of<br />
							<strong>
								Tritanium In-Growth Technology<sup className="futura-bold">1</sup>
							</strong>
						</h2>
						<div>
							<div className="flex mt-40">
								<div className="charTableLeft">
									<div className="fs-19 futura-bold">Design characteristic</div>
								</div>

								<div className="charTableRight">
									<div className="fs-19 futura-bold">Tritanium technology</div>
								</div>
							</div>
							<div className="flex mt-15 mb-15">
								<div className="charTableLeft">
									<p className="futura-bold">
										Strong, stiff and biocompatible material<sup>24</sup>
									</p>
								</div>

								<div className="charTableRight">
									<div className="bulletPoint">
										<div className="gold">
											<i className="las la-check gold" />&nbsp;
										</div>Made from titanium alloy
									</div>
								</div>
							</div>
							<hr className="m-0" />
							<div className="flex mt-15 mb-15">
								<div className="charTableLeft">
									<p className="futura-bold">
										Highly porous material<sup>4,25,26</sup>
									</p>
									<div className="bulletPoint">
										<div className="gold">&bull;&nbsp;</div>
										<p>Porosity {'>'} 46%</p>
									</div>
									<div className="bulletPoint">
										<div className="gold">&bull;&nbsp;</div>
										<p>Average pore diameter {'>'} 300μm</p>
									</div>
								</div>

								<div className="charTableRight">
									<div className="bulletPoint">
										<div className="gold">
											<i className="las la-check gold" />&nbsp;
										</div>
										<p>
											Mean porosity range: 55-65%<sup>3</sup>
										</p>
									</div>
									<div className="bulletPoint">
										<div className="gold">
											<i className="las la-check gold" />&nbsp;
										</div>
										<p>
											Pore size range: 100-700μm<sup>3</sup>
										</p>
									</div>
									<div className="bulletPoint">
										<div className="gold">
											<i className="las la-check gold" />&nbsp;
										</div>
										<p>
											Mean pore size range: 400-500μm<sup>3</sup>
										</p>
									</div>
								</div>
							</div>
							<hr className="m-0" />
							<div className="flex mt-15 mb-15">
								<div className="charTableLeft">
									<p className="futura-bold">
										Interconnected porosity<sup>27</sup>
									</p>
								</div>

								<div className="charTableRight">
									<div className="bulletPoint">
										<div className="gold">
											<i className="las la-check gold" />&nbsp;
										</div>
										<p>
											Porosity on superior and inferior surfaces and within internal walls<sup>3</sup>
										</p>
									</div>
								</div>
							</div>
							<hr className="m-0" />
							<div className="flex mt-15 mb-15">
								<div className="charTableLeft">
									<p className="futura-bold">
										Roughened surface<sup>24,28</sup>
									</p>
								</div>

								<div className="charTableRight">
									<div className="bulletPoint">
										<div className="gold">
											<i className="las la-check gold" />&nbsp;
										</div>
										<p>
											Coefficient of friction = .92<sup>29</sup>
										</p>
									</div>
								</div>
							</div>
							<hr className="m-0" />
							<div className="flex mt-15 mb-15">
								<div className="charTableLeft">
									<p className="futura-bold">
										Porous architecture reflective of bone composition<sup>4</sup>
									</p>
								</div>

								<div className="charTableRight">
									<div className="bulletPoint">
										<div className="gold">
											<i className="las la-check gold" />&nbsp;
										</div>
										<p>
											Tritanium material consists of random interconnected architecture with
											rugged, irregular pore sizes and shapes that are designed to mimic
											cancellous bone
										</p>
									</div>
								</div>
							</div>
							<hr className="m-0" />
							<div className="flex mt-15 mb-15">
								<div className="charTableLeft">
									<p className="futura-bold">
										Manufacturing process capable of reproducible randomization
									</p>
								</div>

								<div className="charTableRight">
									<div className="bulletPoint">
										<div className="gold">
											<i className="las la-check gold" />&nbsp;
										</div>
										<p>
											Utilizes proprietary additive manufacturing technique that is designed to
											produce completely randomized yet reproducible porous structure
										</p>
									</div>
								</div>
							</div>
							<hr className="m-0" />
							<div className="flex mt-15 mb-15">
								<div className="charTableLeft">
									<p className="futura-bold">
										Ability to wick and retain fluid<sup>12</sup>
									</p>
								</div>

								<div className="charTableRight">
									<div className="bulletPoint">
										<div className="gold">
											<i className="las la-check gold" />&nbsp;
										</div>
										<p>
											Tritanium material may be able to wick or retain fluid in comparison to
											traditional titanium material.<sup>12</sup> Tritanium material demonstrated
											the ability to wick fluid into the porous structure under specified
											conditions during an experiment. It also absorbed and held fluid inside the
											porous structure<sup>12</sup>
											<br />
											<br />The Tritanium C Cage absorbed 3 times more bone marrow aspirate (BMA)
											than allograft and 4 times more BMA than PEEK in an in-vitro study<sup>14</sup>
										</p>
									</div>
								</div>
							</div>
							<hr className="m-0" />
							<div className="flex mt-15 mb-15">
								<div className="charTableLeft">
									<p className="futura-bold">
										Realistic environment for cell growth<sup>30</sup>
									</p>
								</div>

								<div className="charTableRight">
									<div className="bulletPoint">
										<div className="gold">
											<i className="las la-check gold" />&nbsp;
										</div>
										<p>
											Coupon built with Tritanium material demonstrated that osteoblasts (cells)
											infiltrated, attached to and proliferated on the porosity of the Tritanium
											technology.<sup>15</sup> The unique porous structure is designed to create a
											favorable environment for cell attachment and proliferation<sup>4,15</sup>
										</p>
									</div>
								</div>
							</div>
							<hr className="m-0" />
						</div>
						<div style={{ color: '#B1B4B3', fontSize: 13, marginTop: 10 }}>
							Note: Tritanium is a novel highly porous titanium material designed for bone in-growth and
							biological fixation. These claims are exclusively associated with Tritanium Technology, used
							to build the Tritanium PL, Tritanium C, Tritanium TL and Monterey AL Cages.
						</div>
					</div>
				</div>

				<div
					className="section flex-ac"
					style={{
						marginTop: 75,
						minHeight: 300,
						width: '100%',
						backgroundImage: `url('/images/Tritanium Banner Background.png')`,
						backgroundSize: 'cover',
						backgroundPosition: 'center'
					}}
				>
					<div className="contentContainer" style={{ padding: '50px 20px' }}>
						<h3 className="egyptienne white">Featuring</h3>
						<h2 className="egyptienne white">
							Tritanium In-Growth Technology<sup>1</sup>
						</h2>
						<h2>
							<strong className="gold">
								Built to fuse<sup>&trade;</sup>
							</strong>
						</h2>
						<div
							style={{ marginTop: 20 }}
							className="primaryButton"
							onClick={() => window.open('mailto:spinemarcomm@stryker.com', '_blank')}
						>
							REQUEST MORE INFORMATION
						</div>
					</div>
				</div>

				<div className="section mt-75">
					<div className="contentContainer">
						<h3 className="futura-bold">For information or image requests, contact:</h3>
						<div className="flex mt-40">
							<div className="flex-ac" style={{ marginRight: 60 }}>
								<img alt="" src={Katie} style={{ width: 100, marginRight: 15 }} />
								<div>
									<p>
										<strong className="gold">Katie Powers</strong>
										<br />
										<strong>Senior Manager, Marketing Communications</strong>
										<br />Stryker
									</p>
								</div>
							</div>

							<div className="flex-ac">
								<img alt="" src={Andrea} style={{ width: 100, marginRight: 15 }} />
								<div>
									<p>
										<strong className="gold">Andrea Sampson</strong>
										<br />
										<strong>President</strong>
										<br />Sullivan & Associates
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="section mt-75">
					<div className="contentContainer">
						<hr className="m-0" />
						<div style={{ fontSize: 11, lineHeight: '19px', marginTop: 25, marginBottom: 50 }}>
							<span style={{ color: '#B1B4B3' }}>
								1. Stryker data on file; PROJ 43909 Tritanium technology claim support memo <br />
								2. Stryker data on file; DHF 53171 Tritanium C <br />
								3. Stryker data on file; DHF 42351 Tritanium PL <br />
								4. Karageorgiou, V. et al. "Porosity of 3D biomaterial scaffolds and osteogenesis."
								Biomaterials. 2005, 26:5474–5491. <br />
								5. Khan, I. et al. "Clinical and cost-effectiveness of lumbar interbody fusion using
								Tritanium Posterolateral Cage (vs. propensity-matched cohort of PEEK cage)." Spine
								Surgery and Related Research, 2022, doi.org/10.22603/ssrr.2021-0252. <br />
								6. Stryker data on file; Stryker’s Spine division <br />
								7. Holmes RE. J Bone Joint Surg AM (1986) 68: 904-911.<br />
								8. Stryker data on file; RD 57968 Tritanium Porosity Quantification via Mercury
								Porosimetry<br />
								9. Loh, Q. et al. "Three-dimensional scaffolds for tissue engineering applications: role
								of porosity and pore size." Tissue engineering. Part B, Reviews. 2013 Dec;19(6):485-502.
								doi: 10.1089/ten.TEB.2012.0437. Epub 2013 Jun 25. PMID: 23672709; PMCID:PMC3826579.<br
								/>
								10. Oh, D. et al. "Distinctive Capillary Action by Microchannels in Bone-like Templates
								can Enhance Recruitment of Cells for Restoration of Large Bony Defect." Journal of
								Visualized Experiments. 2015, 103: 52947. doi:10.3791/52947<br />
								11. Boskey, A. et al. "Bone Structure, Composition, and Mineralization." Orthopedic
								Clinics of North America. 1984, 15: 597-612. doi.org/10.1016/S0030-5898(20)31258-X<br />
								12. Stryker data on file; RD 50927 Tritanium material capillary evaluation <br />
								13. Stryker data on file; TREP 53045 Tritanium wicking verification test report <br />
								14. Stryker data on file; RD 53906 Tritanium Cervical Competitive Wicking Comparison<br
								/>
								15. Stryker data on file; RD 53710 Tritanium cell infiltration and attachment experiment{' '}
								<br />
								16. Stryker data on file; TREP 53692 Tritanium cell attachment verification – report{' '}
								<br />
								17. Stryker data on file; RD 62430 Tritanium hMSC osteogenic differentiation<br />
								18. Reza, A. et al. "Randomized porous titanium impacts cell morphology and induces stem
								cell differentiation in vitro." Orthopaedic Research Society 5th International Spine
								Research Symposium, 2019, 5: 83. <br />
								19. Stryker data on file; RD 57968 Tritanium Porosity Quantification via Mercury
								Porosimetry<br />
								20. Olivares-Navarrete R, Hyzy SL, Slosar PJ, Schneider JM, Schwartz Z, Boyan BD.
								“Implant materials generate different peri-implant inflammatory factors:
								poly-ether-ether-ketone promotes fibrosis and microtextured titanium promotes osteogenic
								factors.” Spine (Phila Pa 1976). 2015 Mar 15;40(6):399-404. doi:
								10.1097/BRS.0000000000000778. PMID: 25584952; PMCID: PMC4363266.<br />
								21. SRL 15-02/Stryker 02-15 Pre-clinical study final report <br />
								22. McGilvray KC, Easley J, Seim HB, Regan D, Berven SH, Hsu WK, Mroz TE, Puttlitz CM.
								“Bony ingrowth potential of 3D-printed porous titanium alloy: a direct comparison of
								interbody cage materials in an in vivo ovine lumbar fusion model.” Spine J. 2018
								Jul;18(7):1250-1260. doi: 10.1016/j.spinee.2018.02.018. Epub 2018 Feb 26. PMID:
								29496624; PMCID: PMC6388616.<br />
								23. Stryker data on file; RD 58163 Tritanium PL Sheep Study Inflammation Report<br />
								24. Oldani C, Dominguez A. Titanium as a Biomaterial for Implants. Recent Advances in
								Arthroplasty. Dr. Samo Fokter (Ed.). ISBN: 978-953-307-990-5. 2012. InTech<br />
								25. Kujala, S. et al (2003): “Effect of porosity on the osteointegration and bone
								ingrowth of a weight-bearing nickel–titanium bone graft substitute”, Biomaterials,
								24(25), November 2003, Pages 4691-4697<br />
								26. Bobyn JD, Pilliar RM, Cameron HU, Weatherly GC. “The optimum pore size for the
								fixation of porous-surfaced metal implants by the ingrowth of bone.” Clin Orthop Relat
								Res. 1980 Jul-Aug;(150):263-70. PMID: 7428231.<br />
								27. Simon JL, Roy TD, Parsons JR, Rekow ED, Thompson VP, Kemnitzer J, et al.
								Engineeredcellular response to scaffold architecture in a rabbit trephine defect. J
								Biomed Mater Res A 2003;66(2):275–82<br />
								28. Deligianni, D.D.; Katsala, N.; Ladas, S.; Sotiropoulou, D.; Amedee, J.; & Missirlis,
								Y.F. (2001) Effect of surface roughness of the titanium alloy Ti-6Al-4V on human bone
								marrow cell response and on protein adsorption. Biomaterials, 22, 1241-1251<br />
								29. Stryker data on file; PROJ 44960 Coefficient of friction memo<br />
								
								30. M. J. Cooke; Enhanced cell attachment using a novel cell culture surface presenting
								functional domains from extracellular matrix proteins. Cytotechnology. 2008 Feb; 56(2):
								71-79
								<br/>
							</span>
							<br />
							<br />
							Refer to the Monterey AL, Tritanium PL, Tritanium TL, and Tritanium C surgical techniques
							and instructions for use for complete product information<br />
							This website is intended for physicians only. It is not intended for patients. If you are a
							patient, you should not rely on the information on this website and should speak to your
							doctor about whether spinal fusion surgery is right for you.<br />
							<br />
							A surgeon must always rely on his or her own professional clinical judgment when deciding
							whether to use a particular product when treating a particular patient. Stryker does not
							dispense medical advice and recommends that surgeons be trained in the use of specific
							products before using them in surgery.
							<br />
							<br />
							The information presented is intended to demonstrate the breadth of Stryker product
							offerings. A surgeon must always refer to the package insert, product label and/or
							instructions for use before using any Stryker product. Products may not be available in all
							markets because product availability is subject to the regulatory and/or medical practices
							in individual markets. Please contact your Stryker representative if you have questions
							about the availability of Stryker products in your area.<br />
							<br /> Stryker Corporation or its divisions or other corporate affiliated entities own, use
							or have applied for the following trademarks or service marks: AMagine, Built to
							fuse,Stryker, Tritanium. All other trademarks are trademarks of their respective owners or
							holders.<br />
							<br /> TRITA-WB-2_Rev 3_34241<br />
							<br /> Copyright &copy; 2022 Stryker
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Home;
