import React from 'react';
import SubNavTagline from '../components/SubNavTagline';
import TritaniumTL from '../assets/images/TritaniumTL_Fig_001_Cover-Cutout.png';
import CTImage from '../assets/images/CT Image.png';
import APXRayImage from '../assets/images/AP X-Ray Image.png';
import LateralXRay from '../assets/images/Lateral X-Ray.png';
import SubsidenceChart from '../assets/images/Subsidence Chart.png';
import AnteriorPlacementSystemImage from '../assets/images/Anterior Placement System Image.png';
import TactileControlImage from '../assets/images/Tactile Control Image.png';
import ProductHighlightImage from '../assets/images/Product Highlight Image.png';

import CommercialBrochure from '../assets/pdf/ASK_Tritanium_TL_Commercial_Brochure_07_11.pdf';

class Product3 extends React.Component {
	render() {
		return (
			<div className="ProductPage">
				<SubNavTagline
					tagline={
						<span className="egyptienne">
							Information for <strong>healthcare professionals</strong>
						</span>
					}
				/>
				<hr />

				<div className="mainSection mt-75" style={{ position: 'relative' }}>
					<div className="contentContainer">
						<div className="flex-ac">
							<div style={{ maxWidth: 500, marginRight: 120 }}>
								<div className="egyptienne mainTitle">
									Redefining <strong>steerability</strong>
								</div>
								<h3 className="mt-15 egyptienne">
									<strong className="gold">
										Tritanium<sup>&reg;</sup> TL
									</strong>
									<br />
									Curved Posterior Lumbar Cage
								</h3>
							</div>
							<img style={{ width: 422 }} alt="" src={TritaniumTL} />
						</div>
						<hr className="mt-75" />
					</div>
				</div>
				<div className="section mt-75">
					<div className="contentContainer">
						<div className="fs-25 futura-bold">
							Tritanium TL's Anterior Placement System and cage design combine to enable the surgeon's{' '}
							<strong className="gold">desired apophyseal placement.</strong>
						</div>
						<p>
							The Tritanium TL Cage is a curved, hollow posterior lumbar cage that consists of a unique configuration of
							both solid and porous structures, which are simultaneously built using 3D Additive
							Manufacturing applying our proprietary Tritanium In-Growth Technology.<sup>1</sup> Tritanium
							TL complements Tritanium PL, and together they offer alternative posterior interbody lumbar
							solutions.
						</p>
						<h2 className="mt-75 egyptienne">
							Created to <strong className="gold">allow imaging</strong>
						</h2>
						<p className="mt-25">
							Designed with lateral windows, the Tritanium TL Cage allows visualization on CT and
							X-ray.<sup>3</sup>
						</p>

						<div className="flex-ac" style={{ marginTop: 37.5 }}>
							<i className="las la-file-alt" style={{ fontSize: 29, marginRight: 12.5 }} />
							<div className="fs-12 pointer" style={{ marginRight: 10 }} onClick={()=>window.open(CommercialBrochure,'_blank')}>
								<strong className="gold">COMMERCIAL BROCHURE</strong>
							</div>
							<div className="arrow-right" />
						</div>
						<div className="threeCircles flex">
							<img style={{ width: 272.5, height: 272.5 }} alt="" src={CTImage} />
							<img style={{ width: 272.5, height: 272.5 }} alt="" src={APXRayImage} />
							<img style={{ width: 272.5, height: 272.5 }} alt="" src={LateralXRay} />
						</div>
						<div className="mt-25" style={{ fontSize: 10, color: '#B1B4B3', textAlign: 'center' }}>
							Images taken from a cadaveric study<sup>3</sup>
						</div>
					</div>
				</div>
				<div className="gradientSection3 mt-75">
					<div className="contentContainer">
						<div className="flex" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
							<div className="floatingBox3">
								<img alt="" src={SubsidenceChart} style={{ width: '100%' }} />
							</div>
							<div style={{ width: 495 }} className="white">
								<h2 className="egyptienne">
									Developed to<br />
									<strong className="gold">
										minimize subsidence<sup>2</sup>
									</strong>
								</h2>
								<p className="mt-20">
									The Tritanium TL Cage demonstrated better resistance to subsidence than other
									commercially available interbody cages constructed out of different materials.<sup>2</sup>
								</p>

								<div className="bulletPoint mt-20">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Central graft and lateral windows help reduce the overall stiffness of the cage
										and allow room for bone graft to be packed inside<sup>2</sup>
									</p>
								</div>
								<div className="bulletPoint mt-20">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Porous Tritanium has an elastic modulus that falls between cancellous and
										cortical bone<sup>4</sup>
									</p>
								</div>
								<div className="bulletPoint mt-20">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Teeth are designed to increase surface contact with bone in order to distribute
										the load and minimize subsidence<sup>4</sup>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="section mt-75">
					<div className="contentContainer">
						<div className="flex" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
							<div style={{ width: 495 }}>
								<h2 className="egyptienne">
									Anterior Placement<br />
									<strong className="gold">System</strong>
								</h2>
								<p className="mt-20">
									Tritanium TL's Anterior Placement System and cage design combine to enable the
									surgeon's desired apophyseal placement.
								</p>
								<div style={{ fontSize: 21, marginTop: 30 }}>
									<strong>Shaped for steerability</strong>
								</div>
								<p className="mt-20">
									Tritanium TL's unique, curved shape and rounded teeth are designed to provide
									tactile feedback and maximize apophyseal bone contact.
								</p>
								<div style={{ fontSize: 21, marginTop: 30 }}>
									<strong>Designed for versatility</strong>
								</div>
								<p className="mt-20">
									Offering two inserter and two tamp options, our system is designed for versatility
									and procedural flexibility, allowing placement in open and MIS procedures.
								</p>

								<div className="bulletPoint mt-20">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										The low-profile, fully-threaded Steerable Inserter allows the cage to be locked
										at different angles and allows for intradiscal visualization of the cage as it
										is impacted and rotated to the surgeon's desired placement
									</p>
								</div>
								<div className="bulletPoint mt-20">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										The low-profile, fully-threaded static Straight Inserter provides a simplified
										and straightforward option for cage insertion
									</p>
								</div>
								<div className="bulletPoint mt-20">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										The Saddle and Half Saddle Tamps directly mate with the implant for minor final
										adjustments to the cage's placement
									</p>
								</div>
							</div>
							<img alt="" src={AnteriorPlacementSystemImage} style={{ width: 495, height: '100%' }} />
						</div>
						<div className="flex mt-75" style={{ justifyContent: 'space-between' }}>
							<img alt="" src={TactileControlImage} style={{ width: 495, height: '100%' }} />
							<div style={{ width: 495 }}>
								<div style={{ fontSize: 21 }}>
									<strong>Created for tactile control</strong>
								</div>
								<div className="bulletPoint mt-15">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										Tritanium TL’s Steerable Inserter is equipped with a friction lock that allows
										simple, in-line insertion and rotation into the disc space, enabling
										visualization during MIS TLIF procedures
									</p>
								</div>
								<div className="bulletPoint mt-15">
									<div className="gold">&bull;&nbsp;</div>
									<p>
										With a unique, curved shape and a roughened Tritanium surface with
										multidirectional teeth, the cage is designed to provide tactile feedback while
										it is steered to its desired placement
									</p>
								</div>
							</div>
						</div>
						<div className="flex mt-75" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
							<div style={{ width: 495 }}>
								<div className="flex-ac">
									<div className="numberCircle" style={{ marginRight: 20 }}>
										1
									</div>
									<p>Smooth, self-distracting nose facilitates cage insertion</p>
								</div>
								<div className="flex-ac mt-20">
									<div className="numberCircle" style={{ marginRight: 20 }}>
										2
									</div>
									<p>
										Rounded teeth enable multidirectional fixation when the cage is steered and
										rotated
									</p>
								</div>
								<div className="flex-ac mt-20">
									<div className="numberCircle" style={{ marginRight: 20 }}>
										3
									</div>
									<p>Curved shape helps steerability and maximizes apophyseal bone contact</p>
								</div>
								<div className="flex-ac mt-20">
									<div className="numberCircle" style={{ marginRight: 20 }}>
										4
									</div>
									<p>
										Threaded inserter attachment for rigid inserter-to-cage connection from
										insertion to optimal placement
									</p>
								</div>
							</div>
							<img alt="" src={ProductHighlightImage} style={{ width: 428, height: '100%' }} />
						</div>
					</div>
				</div>

				<div className="section mt-75">
					<div className="contentContainer">
						<h2 className="egyptienne">
							Tritanium TL Cage{' '}
							<strong className="gold">
								technical data
							</strong>
						</h2>
						<hr className="mt-40" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
							Tritanium material
							</p>
							<p style={{ width: '50%' }}>Titanium alloy</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
							Tritanium mean porosity range<sup>6</sup>
							</p>
							<p style={{ width: '50%' }}>55 - 65%</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
							Tritanium mean pore size range<sup>6</sup>
							</p>
							<p style={{ width: '50%' }}>400 - 500μm</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
							Tritanium pore size range<sup>6</sup>
							</p>
							<p style={{ width: '50%' }}>100 - 700μm</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
							Tritanium pore interconnectedness
							</p>
							<p style={{ width: '50%' }}>Full endplate to endplate</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
								Footprints
							</p>
							<p style={{ width: '50%' }}>10 x 27 mm, 10 x 31 mm, 10 x 35 mm</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
								Lordosis
							</p>
							<p style={{ width: '50%' }}>0, 6, and 12&deg;</p>
						</div>
						<hr className="mt-15" />
						<div className="flex-ac mt-15">
							<p className="humst-black" style={{ width: '50%' }}>
								Heights
							</p>
							<p style={{ width: '50%' }}>7 - 14mm</p>
						</div>
						<hr className="mt-15" />
						<hr className="mt-75" />
						<div style={{ fontSize: 11, lineHeight: '19px', marginTop: 25, marginBottom: 50 }}>
							<span style={{ color: '#B1B4B3' }}>
								1. PROJ43909 | Tritanium technology claim support memo.<br />
								2. PROJ0000056427 | Tritanium TL subsidence memo.<br />
								3. PROJ56903 Tritanium TL imaging memo.<br />
								4. PROJ42624 | Tritanium PL subsidence memo.<br />
								5. Data on file at Stryker Spine<br/>
								6. Stryker data on file; DHF 42351 Tritanium PL 
							</span>
							<br />
							<br />
							Refer to the Monterey AL, Tritanium PL, Tritanium TL, and Tritanium C surgical techniques
							and instructions for use for complete product information<br />
							<br />
							This website is intended for physicians only. It is not intended for patients. If you are a
							patient, you should not rely on the information on this website and should speak to your
							doctor about whether spinal fusion surgery is right for you.<br /><br/>
							A surgeon must always rely on his or her own professional clinical judgment when deciding whether to use a particular product when treating a particular
patient. Stryker does not dispense medical advice and recommends that
surgeons be trained in the use of specific products before using them in surgery.
<br/><br/>
The information presented is intended to demonstrate the breadth of Stryker product offerings. A surgeon must always refer to the package insert, product label and/or instructions for use before using any Stryker product. Products may not be available in all markets because product availability is subject to the regulatory and/or medical practices in individual markets. Please contact your Stryker representative if you have questions about the availability of Stryker products in your area.
							<br /><br/>
							Stryker Corporation or its divisions or other corporate affiliated entities own, use or have
							applied for the following trademarks or service marks: AMagine, Built to fuse,Stryker,
							Tritanium. All other trademarks are trademarks of their respective owners or holders.<br />
							<br />
							TRITA-WB-2_Rev 3_34241 <br />
							<br />
							Copyright &copy; 2022 Stryker
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Product3;
